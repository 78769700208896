import RepoTraffic from './RepoTraffic'
import Version from './Version'

const Home = () => {
  return (
    <>
      <RepoTraffic />
      <Version />
    </>
  )
}

export default Home
